<template>
	<div class="notifications" id="notificationsList" :class="{ group: group, all: !group, start: first_time_loaded, 'to-back': goToGroup }" @click="showAllNotifications">
		<template v-for="(notification, index) in notifications.filter((el) => el.seen == 0)">
			<div class="notification" :style="{ '--index': index }" :id="notification.id">
				<div class="avatar" v-if="notification.avatar">
					<img :src="notification.avatar" />
				</div>
				<div class="icon" v-else-if="notification.frontend_type == 'meetings'">
					<icon-user />
				</div>
				<div class="icon" v-else>
					<icon-bell />
				</div>
				<div class="content-notification" @click="clickOnNotification(notification)">
					<p :class="{ inGroup: group }" v-html="notification.name"></p>
				</div>
				<div class="close" @click.stop="group ? clearAllNotifications() : removeNotification(notification)">
					<icon-close />
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import IconClose from "../Icons/Close";
import IconBell from "../Icons/Bell";
import IconUser from "../Icons/User";

export default {
	data() {
		return {
			notifications: [],
			countUnread: 0,
			group: false,
			first_time_loaded: true,
			goToGroup: false,
			objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK,
			organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK,
		};
	},
	components: {
		IconClose,
		IconBell,
		IconUser,
	},
	async mounted() {
		await this.getNotifications();

		this.$root.$on("updateNotificationsList", (parsedData) => {
			if (parsedData.for_personal) {
				this.notifications.unshift(parsedData);
			}
		});

		window.addEventListener("click", (e) => {
			if (!document.getElementById("notificationsList").contains(e.target)) {
				if (!this.group && this.group != null) {
					this.goToGroup = true;
					setTimeout(() => {
						this.group = true;
						this.goToGroup = false;
					}, 200);
				}
			}
		});
	},
	methods: {
		async getNotifications() {
			await axios
				.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`)
				.then(({ data }) => {
					this.notifications = data.data.notification_logs && data.data.notification_logs.length ? data.data.notification_logs.filter((el) => el.seen == 0) : [];
					this.countUnread = data.data.count_unread;
					if (this.notifications.length > 3) {
						this.group = true;
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				});
		},
		viewItem(itemSlug, notification, column) {
			this.markAsRead(notification);

			if (notification.frontend_type == "procedures") {
				this.$router.push({ name: "procedure-show", params: { slug: itemSlug } });
			} else if (notification.frontend_type == "processes") {
				this.$router.push({ name: "process-show", params: { slug: itemSlug } });
			} else if (notification.frontend_type == "tasks") {
				window.location.href = `${this.organigram_fe_link}/dashboard?open_task=${notification.entity_slug}&date=${notification.additional_data.date}`;
			} else if (notification.frontend_type == "meetings") {
				window.location.href = `${this.organigram_fe_link}/dashboard`;
			} else if (notification.frontend_type == "evaluations") {
				window.location.href = `${this.organigram_fe_link}/user/${itemSlug}`;
			} else if (notification.frontend_type == "role_responsibilities") {
				window.location.href = `${this.organigram_fe_link}/user/${itemSlug}`;
			} else if (notification.frontend_type == "announces") {
				window.location.href = `${this.organigram_fe_link}/announcements/${itemSlug}`;
			} else if (notification.frontend_type == "departments") {
				window.location.href = `${this.organigram_fe_link}/company`;
			} else {
				window.location.href = `${this.objectives_fe_link}/obiectiv/${itemSlug}`;
			}
		},
		markAsRead(notification) {
			axios.patch(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notifications/${notification.id}`).then(({ data }) => {
				var contentNotification = document.querySelector(`#notification-box-${notification.id} .content-notification`);
				if (contentNotification) {
					contentNotification.classList.remove("new");
				}
			});
		},
		removeNotification(notification, all) {
			const element = document.getElementById(notification.id);
			element.remove();
			if (!all) {
				this.markAsRead(notification);
			}
		},
		showAllNotifications() {
			this.first_time_loaded = false;
			this.group = false;
		},
		clearAllNotifications() {
			this.notifications.map((el) => this.removeNotification(el, true));

			axios.post(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/${this.$auth.user().master_instance_id}/notifications/seen-all`);
		},
		clickOnNotification(notification) {
			if (this.group == false) {
				if (notification.need_link) {
					this.viewItem(notification.entity_slug, notification, "personal");
				}
			}
		},
	},
};
</script>
