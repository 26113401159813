<template>
	<div class="main-template">
        <template v-if="loadedApplications">
            <sidebar v-if="$resize && $mq.above(781)" />
            <div class="container-tbf">
                <navbar />
                <div class="container-content">
                    <slot></slot>
                </div>
                <sidebar-mobile v-if="$resize && $mq.below(780)"/>
            </div>
            <notifications/>

            <div class="overflow-modal" v-if="show_overflow">
                <div class="bg-modals" @click="closeModals()"></div>
                <modal-publish id="modal_publish" v-if="show_modal.publish" :data="data.publish" @close_modal="closeModal('publish')" />
                <modal-clone id="modal_clone" v-if="show_modal.clone" :data="data.clone" @close_modal="closeModal('clone')" />
                <modal-improvements id="modal_improvements" v-if="show_modal.improvements" :data="data.improvements" @close_modal="closeModal('improvements')"/>
                <modal-required-instance id="modal_required_instance" v-if="show_modal.required_instance" :data="data.required_instance" @close_modal="closeModal('required_instance')"/>
                <modal-change-subscription id="modal_change_subscription" v-if="show_modal.change_subscription" @close_modal="closeModal('change_subscription')"/>
                <modal-role id="modal_role" v-if="show_modal.role" @close_modal="closeModal('role')" />
                <modal-change-improvement-status id="modal_change_improvement_status" v-if="show_modal.change_improvement_status" :data="data.change_improvement_status" @close_modal="closeModal('change_improvement_status')" />
                <modal-grace-period id="modal_payment_error" v-if="show_modal.payment_error" @close_modal="closeModal('payment_error')"/>
                <modal-warning-limit-package id="modal_warning_limit_package" v-if="show_modal.warning_limit_package" @close_modal="closeModal('warning_limit_package')"/>
                <modal-delete id="modal_delete" v-if="show_modal.delete" :data="data.delete" @close_modal="closeModal('delete')" />
                <modal-notifications id="modal_notifications" v-if="show_modal.notifications" @close_modal="closeModal('notifications')"/>
                <modal-settings-mobile id="modal_settings_mobile" v-if="show_modal.settings_mobile" @close_modal="closeModal('settings_mobile')"/>
                <modal-entries-procedure-users id="modal_entries_procedure_users" v-if="show_modal.entries_procedure_users" :data="data.entries_procedure_users" @close_modal="closeModal('entries_procedure_users')"/>
                <modal-reset-password id="modal_reset_password" v-if="show_modal.reset_password" :data="data.reset_password" @close_modal="closeModal('reset_password')"/>
                <modal-webinar-tehnic id="modal_webinar_tbf" v-if="show_modal.webinar_tbf" @close_modal="closeModal('webinar_tbf')"/>
                <modal-contact id="modal_contact" v-if="show_modal.contact" @close_modal="closeModal('contact')"/>
                <modal-confirm-archiving id="modal_confirm_archiving" v-if="show_modal.confirm_archiving" :data="data.confirm_archiving" @close_modal="closeModal('confirm_archiving')" />
                <modal-procedure-check id="modal_procedure_check" v-if="show_modal.procedure_check" :data="data.procedure_check" @close_modal="closeModal('procedure_check')"/>
                <modal-mission-vision id="modal_mission_vision" v-if="show_modal.mission_vision" @close_modal="closeModal('mission_vision')" />
                <modal-report-procedures-checks id="modal_report_procedures_checks" v-if="show_modal.report_procedures_checks" :data="data.report_procedures_checks" @close_modal="closeModal('report_procedures_checks')"/>
            </div>
        </template>
        <div class="page-loader-on-start" v-else>
            <div class="loader-spin"></div>
        </div>
	</div>
</template>

<script>
    import Sidebar from '../General/Sidebar'
    import Navbar from '../General/Navbar'
    import ModalPublish from '../Modals/PublishProcedure'
    import ModalClone from '../Modals/Clone'
    import ModalImprovements from "../Modals/Improvements"
    import ModalRequiredInstance from "../Modals/RequiredInstance"
    import ModalChangeSubscription from "../Modals/ChangeSubscription"
    import ModalRole from "../Modals/Role"
    import ModalDelete from "../Modals/Delete"
    import ModalChangeImprovementStatus from "../Modals/ChangeImprovementStatus"
    import ModalNotifications from "../General/SidebarNotifications"
    import ModalGracePeriod from "../Modals/GracePeriod"
    import ModalWarningLimitPackage from "../Modals/WarningLimitPackage"
    import SidebarMobile from "../General/SidebarMobile"
    import ModalSettingsMobile from "../General/SidebarSettingsMobile"
    import ModalEntriesProcedureUsers from "../Modals/EntriesProcedureUsers"
    import ModalResetPassword from "../Modals/ResetPassword"
    import ModalWebinarTehnic from "../Modals/WebinarTehnic"
    import ModalContact from "../Modals/Contact"
    import ModalConfirmArchiving from "../Modals/ConfirmArchiving"
    import ModalProcedureCheck from "../Modals/ProcedureCheck"
    import ModalMissionVision from "../Modals/MissionVision"
    import ModalReportProceduresChecks from "../Modals/ReportProceduresChecks"
    import Notifications from "../General/Notifications"

    export default {
        data() {
            return {
                loadedApplications: false,
                show_overflow: false,
                show_modal: {
                    publish: false,
                    clone: false,
                    improvements: false,
                    required_instance: false,
                    role: false,
                    change_improvement_status: false,
                    delete: false,
                    payment_error: false,
                    change_subscription: false,
                    settings_mobile: false,
                    entries_procedure_users: false,
                    warning_limit_package: false,
                    reset_password: false,
                    contact: false,
                    confirm_archiving: false,
                    webinar_tbf: false,
                    mission_vision: false,
                    procedure_check: false
                },
                data: {
                    clone: {},
                    improvements: {},
                    required_instance: {},
                    change_improvement_status: {},
                    delete: {},
                    entries_procedure_users: {},
                    publish: {},
                    reset_password: {},
                    confirm_archiving: {},
                    report_procedures_checks: {},
                    procedure_check: {}
                }
            }
        },
        components: {
            Sidebar,
            Navbar,
            ModalPublish,
            ModalClone,
            ModalImprovements,
            ModalRequiredInstance,
            ModalChangeSubscription,
            ModalRole,
            ModalChangeImprovementStatus,
            ModalNotifications,
            ModalDelete,
            ModalGracePeriod,
            SidebarMobile,
            ModalSettingsMobile,
            ModalEntriesProcedureUsers,
            ModalWarningLimitPackage,
            ModalResetPassword,
            ModalContact,
            ModalWebinarTehnic,
            ModalConfirmArchiving,
            ModalProcedureCheck,
            ModalMissionVision,
            ModalReportProceduresChecks,
            Notifications
        },
        mounted(){
            this.$root.$on("open_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })

            this.$root.$on('change_modal', (type) => {
				$("#modal_" + this.active_modal).removeClass("active")
				setTimeout(() => {
					this.show_modal[this.active_modal] = false
					setTimeout(() => {
						this.show_modal[type] = true
						this.active_modal = type
						setTimeout(() => {
							$("#modal_" + type).addClass("active");
						}, 0);
					}, 0);
				}, 200);
            })

            if(this.$store.state.applications.applications.length){
                this.initItercom();
                this.loadedApplications = true;
            } else {
                var refreshIntervalApps = setInterval(() => {
                    if(this.$auth.check()){
                        if(this.$store.state.applications.applications.length){
                            clearInterval(refreshIntervalApps);
                            this.initItercom();
                            this.loadedApplications = true;
                        }
                    }
                }, 50);
            }

            if(this.$auth.check() && this.$auth.user().rights.instance == 'grace_period' && !this.$cookies.get('grace_period_modal')){
                this.$cookies.set('grace_period_modal', true, '1d')
            	this.$root.$emit('open_modal', 'payment_error')
            }

            this.checkLanguage();
        },
        methods:{
            closeModals(){
                var arrayModalsNeedVerification = ['key_result', 'objective', 'master_goal', 'user', 'add_promise', 'evaluate_promise']
                if(arrayModalsNeedVerification.includes(this.active_modal)){
                    this.$root.$emit('confirm_close')
                }else{
                    if(this.active_modal == 'procedure') {
                        this.closeModal(false, false)
                        this.active_modal = 'add_step'
                    }else {
                        this.closeModal()
                    }
                }
            },
            closeModal(type = false, closeOverflow = true){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false

                    if(closeOverflow){
                        $('.overflow-modal').removeClass('show')
                        setTimeout(() => {
                            this.show_overflow = false;
                        }, 200)
                    }
                }, 200);
            },
            initItercom(){
                    var apps = {
                        'Obiective': false,
                        'Proceduri': false,
                        'Cursuri': this.$auth.user().instance && this.$auth.user().instance.id <= 1340 ? true : false,
                        'Organigrama': false    
                    };
                    
                    this.$store.state.applications.applications.map((el) => {
                        if(el.application){
                            apps[el.application.name] = el.is_active;
                        }
                    });

                    Intercom('boot', {  
                        app_id: "tk8wk42t",
                        name: this.$auth.user().first_name + ' ' + this.$auth.user().last_name,
                        email: this.$auth.user().email,
                        package_id: this.$auth.user().instance ? this.$auth.user().instance.package_id : '',
                        user_hash: this.$auth.user().intercom_token,
                        created_at: null,
                        hide_default_launcher: this.$resize && this.$mq.above(780) ? false : true,
                        'Obiective': apps['Obiective'],
                        'Proceduri': apps['Proceduri'],
                        'Cursuri': apps['Cursuri'],
                        'Organigrama': apps['Organigrama'] 
                    });
            },
            checkLanguage(){
				if(this.$auth.check()){
					this.$i18n.locale = this.$auth.user().language
					this.$cookies.set('language', this.$auth.user().language, '1m')
				}else if(this.$cookies.get('language')){
					this.$i18n.locale = this.$cookies.get('language')
				}
			}
        }
    }
</script>

<style lang="scss">
    @import "../../styles/templates/main-template.scss";
</style>