<template>
	<div class="full-template">
        <slot></slot>
        <notifications/>

        <div class="overflow-modal" v-if="show_overflow">
            <div class="bg-modals" @click="closeModals()"></div>
            <modal-add-step id="modal_add_step" v-if="show_modal.add_step" :data="data.add_step" :modalType="modalStepType" :json="json_process" :processData="processData" @close_modal="closeModal('add_step')"/>
            <modal-edit-step id="modal_edit_step" v-if="show_modal.edit_step" :data="data.edit_step" :json="json_process" :processData="processData" @close_modal="closeModal('edit_step')"/>
            <modal-version id="modal_version" v-if="show_modal.version" :data="data.version" :itemId="item.version" @close_modal="closeModal('version')"/>
            <modal-improvements id="modal_improvements" v-if="show_modal.improvements" :data="data.improvements" @close_modal="closeModal('improvements')"/>
            <modal-procedure id="modal_procedure" v-if="show_modal.procedure" :data="data.procedure" :itemId="item.procedure" @close_modal="closeModal('procedure', false)"/>
            <modal-delete id="modal_delete" v-if="show_modal.delete" :data="data.delete" @close_modal="closeModal" />
            <modal-change-improvement-status id="modal_change_improvement_status" v-if="show_modal.change_improvement_status" :data="data.change_improvement_status" @close_modal="closeModal('change_improvement_status')" />

            <sidebar-chart id="modal_sidebar_chart" v-if="show_modal.sidebar_chart" :itemSlug="item.sidebar_chart" @close_modal="closeModal('sidebar_chart')"/>
            <sidebar-improvements id="modal_sidebar_improvements" v-if="show_modal.sidebar_improvements" :data="data.sidebar_improvements" @close_modal="closeModal('sidebar_improvements')"/>
        </div>
	</div>
</template>

<script>
    import ModalAddStep from "../Modals/AddStep/Index"
    import ModalEditStep from "../Modals/EditStep"
    import ModalVersion from "../Modals/CreateVersion"
    import ModalProcedure from "../Modals/Procedure"
    import ModalImprovements from "../Modals/Improvements"
    import ModalChangeImprovementStatus from "../Modals/ChangeImprovementStatus"
    import ModalDelete from "../Modals/Delete"
    import SidebarChart from "../Pages/Processes/SidebarChart"
    import SidebarImprovements from '../Pages/Processes/SidebarImprovements.vue'
    import Notifications from "../General/Notifications"

    export default {
        data() {
            return {
                active_modal: '',
                show_overflow: false,
                show_modal: {
                    sidebar_chart: false,
                    sidebar_improvements: false,
                    add_step: false,
                    edit_step: false,
                    procedure: false,
                    delete: false,
                    improvements: false,
                    version: false,
                    change_improvement_status: false
                },
                json_process: '',
                data: {
                    add_step: '',
                    edit_step: '',
                    version: {},
                    procedure: {},
                    delete: {},
                    improvements: {},
                    sidebar_improvements: {},
                    change_improvement_status: {}
                },
                item: {
                    sidebar_chart: '',
                    version: '',
                    procedure: ''
                },
                processData: {},
                modalStepType: ''
            }
        },
        components: {
            SidebarChart,
            ModalAddStep,
            ModalEditStep,
            ModalVersion,
            ModalProcedure,
            ModalDelete,
            ModalChangeImprovementStatus,
            ModalImprovements,
            SidebarImprovements,
            Notifications
        },
        mounted(){
            Intercom('shutdown');
            
            this.$root.$on("open_modal", (type, data = false, itemId = false) => {
                this.active_modal = type
                this.item[type] = itemId ? itemId : ''
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })

            this.$root.$on('change_modal', (type) => {
				$("#modal_" + this.active_modal).removeClass("active")
				setTimeout(() => {
					this.show_modal[this.active_modal] = false
					setTimeout(() => {
						this.show_modal[type] = true
						this.active_modal = type
						setTimeout(() => {
							$("#modal_" + type).addClass("active");
						}, 0);
					}, 0);
				}, 200);
            })

            this.$root.$on("open_modal_step", (type, stepType = false, data = false, json = false, processData) => {
                this.active_modal = type
                this.json_process = json ? json : ''
                this.processData = processData
                this.data[type] = data ? data : ''
                this.modalStepType = stepType ? stepType : ''

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })
            
            this.checkLanguage();
        },
        methods: {
            closeModal(type = false, closeOverflow = true){
                if(this.active_modal == 'procedure' || (this.active_modal == 'delete' && this.show_modal.version)) {
                    closeOverflow = false
                }

                var target_modal = type == 'delete_version' ? 'delete' : (type ? type : this.active_modal)
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false
                    if(closeOverflow){
                        $('.overflow-modal').removeClass('show')
                        setTimeout(() => {
                            this.show_overflow = false;
                        }, 200)
                    }
                }, 200);

                if(!closeOverflow){
                    if(this.active_modal == 'procedure'){
                        this.active_modal = 'add_step'
                    }
                    if(this.show_modal.version){
                        this.active_modal = 'version'
                    }

                    if(type == 'delete_version'){
                        this.closeModal()
                    }
                }
            },
            closeModals(){
                var arrayModalsNeedVerification = ['procedure']
                
                if(arrayModalsNeedVerification.includes(this.active_modal)){
                    this.$root.$emit('confirm_close')
                }else{
                    this.closeModal()
                }
            },
            checkLanguage(){
                if(this.$auth.check()){
                   this.$i18n.locale = this.$auth.user().language
                   this.$cookies.set('language', this.$auth.user().language, '1m')
                }else if(this.$cookies.get('language')){
                   this.$i18n.locale = this.$cookies.get('language')
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../styles/templates/full-template.scss";
    @import "../../styles/pages/list_improvements.scss";
    @import "../../styles/pages/organigram.scss";
</style>