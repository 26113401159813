<template>
	<div class="sidebar-org">
		<div class="close-button" v-if="$resize && $mq.above(601)">
			<button class="btn-tbf white only-icon" @click="closeModal"><icon-close /></button>
		</div>
		<div class="content-sidebar" v-if="loaded">
			<div class="header">
				<div class="title">
					<div class="text">{{ procedure.name }}</div>
				</div>
				<div class="action buttons">
					<router-link :to="{name: 'procedure-show', params: { slug: itemSlug } }" class="btn-edit show"><icon-show /></router-link>
					<router-link v-if="procedure.rights.entity_crud" :to="{name: 'procedure-edit', params: { slug: itemSlug } }" class="btn-edit"><icon-edit /></router-link>
					<div class="close-button" v-if="$resize && $mq.below(600)">
						<button class="btn-tbf white only-icon" @click="closeModal"><icon-close /></button>
					</div>
				</div>
			</div>

			<div class="page-show-procedure content">
				<div class="content-procedure">
					<div class="step" v-for="(step,index) in procedure.steps">
						<!-- <div v-show="$resize && $mq.above(781)" class="step-count"><p class="number">{{index + 1}}</p></div> -->
						<div class="title-step">{{ $t('procedures.step_count', {n: (index + 1)}) }}:</div>
						<div class="body" v-html="step.body"></div>
					</div>
				</div>

				<div class="sidebar-procedure modal">
					<div class="manager-row" v-if="procedure.responsible">
						<div class="manager">
							<div class="image">
								<img :src="procedure.responsible.avatar" v-if="procedure.responsible.avatar">
								<div class="user-circle" v-else>
									<icon-user />
								</div>
							</div>
							<div class="data">
								<div class="tag">{{ $t('procedures.responsible') }}</div>
								<div class="name">{{ `${procedure.responsible.first_name} ${procedure.responsible.last_name ? procedure.responsible.last_name : ''}` }}</div>
							</div>
						</div>
					</div>

					<div class="box-label-text" v-if="procedure.roles.length">
						<div class="label">{{ $t('procedures.roles') }}</div>
						<div class="text">{{ procedure.roles.map((el) => { return el['name']}).join(", ") }}</div>
					</div>

					<div v-if="procedure.rights.entity_crud" class="last-update"><router-link :to="{name: 'procedure-history', params: {slug: procedure.slug} }" class="btn-tbf-link">{{ $tc('procedures.count_updates', procedure.total_procedure_histories) }}, {{ $t('general.last_update') }} {{ procedure.updated_at | moment('DD MMM YYYY') }}</router-link></div>

					<div class="accordion-sidebar" v-if="procedure.triggers.length">
						<div class="header-accordion" data-toggle="collapse" data-target="#collapseTrigger">
							<div class="icon"><icon-trigger /></div>

							<div class="title">{{ $t('procedures.triggers') }}</div>
							<div class="actions">
								<button class="arrow-collapse show" type="button" data-toggle="collapse" data-target="#collapseTrigger" aria-expanded="true">
									<icon-arrow />
								</button>
							</div>
						</div>

						<div class="collapse show" id="collapseTrigger">
							<div class="content-accordion">
								<div class="box-radius" v-for="trigger in procedure.triggers">
									<div class="data">
										<div class="label">{{ $t('trigger.text.' + trigger.type)}}</div>
										<div class="value" v-if="trigger.type == 'procedure'">{{ trigger.entity_name }}</div>
										<div class="value" v-if="trigger.type == 'date'">{{ $tc('procedures.date_of', trigger.value) }}</div>
										<div class="value" v-if="trigger.type == 'weekly'">{{ trigger.value.split(",").map(el => { return $t(`calendar.long_days.${el}`) }).join(', ') }}</div>
										<div class="value" v-if="trigger.type == 'event'">{{ trigger.value }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="accordion-sidebar">
						<div class="header-accordion" data-toggle="collapse" data-target="#collapseStatistics">
							<div class="icon"><icon-statistics /></div>
							<div class="title">{{ $t('procedures.statistics') }}</div>
							<div class="actions">
								<button class="arrow-collapse show" type="button" data-toggle="collapse" data-target="#collapseStatistics" aria-expanded="true">
									<icon-arrow />
								</button>
							</div>
						</div>

						<div class="collapse show" id="collapseStatistics">
							<div class="content-accordion">
								<div class="box-radius">
									<div class="inline-data">
										<div class="label">{{ $t('procedures.total_time') }}</div>
										<div class="value">{{procedure.analytics.total_time}}</div>
									</div>
									<div class="inline-data">
										<div class="label">{{ $t('procedures.number_visits') }}</div>
										<div class="value">{{procedure.analytics.entries}}</div>
									</div>
									<div class="inline-data">
										<div class="label">{{ $t('procedures.average_time') }}</div>
										<div class="value">{{procedure.analytics.average_time}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="accordion-sidebar">
						<div class="header-accordion" data-toggle="collapse" data-target="#collapseImprovements">
							<div class="icon"><icon-question /></div>
							<div class="title">{{ $t('procedures.improvement_requests') }} ({{procedure.total_improvement_proposals}})</div>

							<div class="actions">
								<button class="arrow-collapse show" type="button" data-toggle="collapse" data-target="#collapseImprovements" aria-expanded="true">
									<icon-arrow />
								</button>
							</div>
						</div>

						<div v-if="procedure.improvement_proposals.length" class="collapse show" id="collapseImprovements">
							<div class="content-accordion">
								<div class="box-radius box-improvement" v-for="improvement in procedure.improvement_proposals">
									<div class="header-user">
										<div class="user">
											<div class="image">
												<img :src="improvement.user.avatar" v-if="improvement.user.avatar">
												<div class="user-circle" v-else>
													<icon-user />
												</div>
											</div>
											<div class="data">
												<div class="tag">{{ improvement.created_at | moment('DD MMM YYYY') }}</div>
												<div class="name">{{ `${improvement.user.first_name} ${improvement.user.last_name ? improvement.user.last_name : ''}` }}</div>
											</div>
										</div>
										<div class="dropdown-status">
											<div class="status">
												<div class="circle-status" v-bind:class="{green: improvement.status == 'active', blue: improvement.status == 'featuring', orange: improvement.status == 'archived'}"></div>
												<div class="text">{{ $t('improvements.status.' + improvement.status) }}</div>
											</div>
										</div>
									</div>
									<div class="text">{{ improvement.body }}</div>
									<div class="files">
										<div class="file" v-for="file in improvement.files">
											<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 0, hide: 0}">
												<a class="file-box" :href="file.file_path" target="_blank">
													<div class="icon"><icon-file /></div>
												</a>

												<template slot="popover">
													<div class="simple-text" >{{ file.filename }}</div>
												</template>
											</v-popover>
										</div>
									</div>
								</div>
								<div class="view-more">
									<router-link :to="{name: 'procedure-improvements', params: {slug: procedure.slug} }" class="btn-tbf grey center view-more-btn">
										<div class="text">{{ $t('procedures.view_more') }}</div>
									</router-link >
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconArrow from '../../Icons/Arrow'
	import IconClose from '../../Icons/Close'
	import IconUser from '../../Icons/User'
	import IconEdit from '../../Icons/Edit'
	import IconFile from '../../Icons/File'
	import IconQuestion from '../../Icons/Question'
	import IconTrigger from '../../Icons/Trigger'
	import IconStatistics from '../../Icons/Statistics'
	import IconShow from '../../Icons/Show'

	export default {
		components: {
			IconArrow,
			IconClose,
			IconUser,
			IconQuestion,
			IconTrigger,
			IconEdit,
			IconStatistics,
			IconFile,
			IconShow
		},
		data() {
			return {
				loaded: false,
				procedure: {},
				timer: {
					startTime: '',
					timeOnPage: 0,
					timePaused: 'start',
					countTime: ''
				}
			}
		},
		props:{
			itemSlug: String
		},
		created() {
			window.addEventListener('beforeunload', this.storeTime)
		},
		async mounted(){
			await this.getProcedure()

			this.$root.$on('refreshProcedureShow', () => {
				this.getProcedure()
			})

			this.countTimeOnPage()

			$(window).on("blur focus", (e) => {
				var prevType = $(this).data("prevType");
				if (prevType != e.type) {
					switch (e.type) {
						case "blur":
							this.timePaused = 'stop'
							break;
						case "focus":
							this.timePaused = 'start'
							break;
					}
				}

				$(this).data("prevType", e.type);
			})
		},
		beforeDestroy () {
			clearInterval(this.timer.countTime);

			axios.post(`/procedure-logs/${this.procedure.slug}/store`, {time: this.timer.timeOnPage}).catch(error => {
				if(error.response){
					if(error.response.status == 403) {
						this.$router.push({name: 'forbbiden'})
					}else if(error.response.status == 404) {
						this.$router.push({name: 'not-found'})
					} else if(error.response.status == 500) {
						alert(this.$t('error.500'))
					}
				}
			})

			window.removeEventListener('beforeunload', this.storeTime)
		},
		methods: {
			async getProcedure(){
				await axios.get(`procedures/${this.itemSlug}`)
				.then(({data}) => {
					this.procedure = data.data
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			closeModal(){
				this.$emit("close_modal");
			},
			countTimeOnPage(){
				this.timer.startTime = moment().format('DD-MM-YYYY HH:mm:ss')
				this.timer.countTime = setInterval(() => {
					if(this.timer.timePaused == 'start'){
						this.timer.timeOnPage += 1
					}
				}, 1000)
			},
			storeTime(event){
				event.preventDefault()
				try {
					axios.post(`/procedure-logs/${this.procedure.slug}/store`, {time: this.timer.timeOnPage})
					return ''
				} catch (error) {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}
			}
		}
	}
</script>

<style lang="scss">
	.sidebar-org{
		.page-show-procedure{
			border-top: 1px solid $borderColor;
			margin-top: 30px;
		}
	}
</style>