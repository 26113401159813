<template>
	<div class="sidebar-tbf" :class="{ open: showMenuExtended }">
		<!-- <div class="toggle-menu" @click="toggleMenu">
			<icon-toggle-menu />
		</div> -->

		<div class="top-sidebar">
			<div class="logo-tbfdigital" @mouseover="logoAnim">
				<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="59" :width="48" v-on:animCreated="handleAnimationLogo" />
			</div>
			<div class="soft-name">TBF Digital</div>
		</div>
		<div class="main-menu" v-if="$auth.check()">
			<div class="menu-list">
				<!-- :href="`${objectives_fe_link}/obiective`" -->
				<div class="item-menu" :title="$t('navbar.dashboard')">
					<a class="menu-link" :href="`${organigram_fe_link}/dashboard`">
						<div class="border-left-active"></div>
						<div class="icon-menu">
							<icon-dashboard class="icon-item" />
						</div>
						<div class="text-link">{{ $t("navbar.dashboard") }}</div>
					</a>
				</div>
				<div class="item-menu" :title="$t('general.projects')">
					<a class="menu-link" :href="`${organigram_fe_link}/projects`">
						<div class="border-left-active"></div>
						<div class="icon-menu">
							<icon-projects class="icon-item" />
						</div>
						<div class="text-link">{{ $t("general.projects") }}</div>
					</a>
				</div>
				<div class="item-menu has-submenu" v-bind:class="{ active_submenu: subMenusActives.includes('org') }" :title="$t('sidebar_tbf.vartej')" @click="toggleSubMenu('org')">
					<div class="menu-link">
						<div class="border-left-active"></div>
						<div class="icon-menu">
							<icon-tornado class="icon-item" />
						</div>
						<div class="text-link">{{ $t("sidebar_tbf.vartej") }}</div>
					</div>
					<div class="arrow-submenu">
						<icon-arrow />
					</div>
				</div>
				<div class="sub-menu-box" :class="{ hide: !subMenusActives.includes('org') }">
					<div class="item-menu sub-menu" :title="$t('sidebar_tbf.organigram')">
						<a class="menu-link" :href="`${organigram_fe_link}/company`">
							<div class="border-left-active"></div>
							<div class="icon-menu">
								<div class="line" v-if="false"></div>
								<icon-chart class="icon-item" v-else />
							</div>
							<div class="text-link">{{ $t("sidebar_tbf.organigram") }}</div>
						</a>
					</div>
					<div class="item-menu sub-menu" :title="$t('sidebar_tbf.vacant_jobs')" v-if="$auth.user().rights.admins_instance_crud">
						<a class="menu-link" :href="`${organigram_fe_link}/vacant-jobs`">
							<div class="border-left-active"></div>
							<div class="icon-menu">
								<div class="line" v-if="false"></div>
								<icon-vacant-job class="icon-item" v-else />
							</div>
							<div class="text-link">{{ $t("sidebar_tbf.vacant_jobs") }}</div>
						</a>
					</div>
					<div class="item-menu sub-menu" :title="$t('sidebar_tbf.roles')">
						<a class="menu-link" :href="`${organigram_fe_link}/roles`">
							<div class="border-left-active"></div>
							<div class="icon-menu">
								<div class="line" v-if="false"></div>
								<icon-roles class="icon-item" v-else />
							</div>
							<div class="text-link">{{ $t("sidebar_tbf.roles") }}</div>
						</a>
					</div>
					<div class="item-menu sub-menu" :title="$t('sidebar_tbf.holidays')">
						<a class="menu-link" :href="`${organigram_fe_link}/holidays`">
							<div class="border-left-active"></div>
							<div class="icon-menu">
								<div class="line" v-if="false"></div>
								<icon-calendar class="icon-item" v-else />
							</div>
							<div class="text-link">{{ $t("sidebar_tbf.holidays") }}</div>
						</a>
					</div>

					<div class="item-menu sub-menu" :title="$t('sidebar_tbf.procedures')">
						<router-link class="menu-link" :to="{ name: 'procedures' }" :class="{ active: $route.name == 'procedures' }">
							<div class="border-left-active"></div>
							<div class="icon-menu">
								<div class="line" v-if="false"></div>
								<icon-procedures class="icon-item" v-else />
							</div>
							<div class="text-link">{{ $t("sidebar_tbf.procedures") }}</div>
						</router-link>
					</div>
					<div class="item-menu sub-menu" :title="$t('sidebar_tbf.processes')">
						<router-link class="menu-link" :to="{ name: 'processes' }" :class="{ active: $route.name == 'processes' }">
							<div class="border-left-active"></div>
							<div class="icon-menu">
								<div class="line" v-if="false"></div>
								<icon-processes class="icon-item" v-else />
							</div>
							<div class="text-link">{{ $t("sidebar_tbf.processes") }}</div>
						</router-link>
					</div>
				</div>

				<div class="item-menu has-submenu" v-bind:class="{ active_submenu: subMenusActives.includes('objectives') }" :title="$t('sidebar_tbf.objectives')" @click="toggleSubMenu('objectives')">
					<div class="menu-link">
						<div class="border-left-active"></div>
						<div class="icon-menu">
							<icon-chart-up class="icon-item" />
						</div>
						<div class="text-link">{{ $t("sidebar_tbf.crestere") }}</div>
					</div>
					<div class="arrow-submenu">
						<icon-arrow />
					</div>
				</div>
				<div class="sub-menu-box" :class="{ hide: !subMenusActives.includes('objectives') }">
					<div class="item-menu sub-menu" :title="$t('sidebar_tbf.objectives')">
						<a class="menu-link" :href="`${objectives_fe_link}/obiective`">
							<div class="border-left-active"></div>
							<div class="icon-menu">
								<div class="line" v-if="false"></div>
								<icon-objectives class="icon-item" v-else />
							</div>
							<div class="text-link">{{ $t("sidebar_tbf.objectives") }}</div>
						</a>
					</div>
					<div class="item-menu sub-menu" :title="$t('sidebar_tbf.objectives-master-goal')">
						<a class="menu-link" :href="`${objectives_fe_link}/master-goals`">
							<div class="border-left-active"></div>
							<div class="icon-menu">
								<div class="line" v-if="false"></div>
								<icon-master-goals class="icon-item" v-else />
							</div>
							<div class="text-link">{{ $t("sidebar_tbf.objectives-master-goal") }}</div>
						</a>
					</div>
					<div class="item-menu sub-menu" :title="$t('sidebar_tbf.objectives-smart-planner')">
						<a class="menu-link" :href="`${objectives_fe_link}/smart-planner`">
							<div class="border-left-active"></div>
							<div class="icon-menu">
								<div class="line" v-if="false"></div>
								<icon-smart-planner class="icon-item" v-else />
							</div>
							<div class="text-link">{{ $t("sidebar_tbf.objectives-smart-planner") }}</div>
						</a>
					</div>
				</div>

				<div class="item-menu" :title="$t('navbar.users')">
					<a class="menu-link" :href="`${organigram_fe_link}/users`">
						<div class="border-left-active"></div>
						<div class="icon-menu">
							<icon-people class="icon-item" />
						</div>
						<div class="text-link">{{ $t("navbar.users") }}</div>
					</a>
				</div>

				<div :title="$t('navbar.reports')" class="item-menu">
					<a class="menu-link" :href="`${organigram_fe_link}/reports`">
						<div class="border-left-active"></div>
						<div class="icon-menu">
							<icon-reports class="icon-item" />
						</div>
						<div class="text-link">{{ $t("navbar.reports") }}</div>
					</a>
				</div>
				<div class="item-menu" :title="$t('sidebar_tbf.settings')">
					<a class="menu-link" :href="`${organigram_fe_link}/settings/profile`">
						<div class="border-left-active"></div>
						<div class="icon-menu">
							<icon-settings class="icon-item" />
						</div>
						<div class="text-link">{{ $t("sidebar_tbf.settings") }}</div>
					</a>
				</div>
			</div>
		</div>
		<div class="bottom-sidebar">
			<div class="menu-list">
				<div class="item-menu" title="Toggle Menu">
					<div class="toggle-menu">
						<div class="icon-menu">
							<div class="box-icon" @click="toggleMenu">
								<icon-menu-bars class="icon-item" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Lottie from "vue-lottie";
import * as animationDataLogo from "../../assets/animations/tbfdigital_logo.json";
import IconDailyPlan from "../Icons/DailyPlan";
import IconChart from "../Icons/Chart";
import IconDashboard from "../Icons/Dashboard";
import IconPeople from "../Icons/People";
import IconRoles from "../Icons/Tags";
import IconVacantJob from "../Icons/VacantJob";
import IconReports from "../Icons/Reports";
import IconSettings from "../Icons/Settings";
import IconLogout from "../Icons/Logout";
import IconChartUp from "../Icons/ChartUp";
import IconProcedures from "../Icons/Procedures";
import IconProcesses from "../Icons/Processes";
import IconArrow from "../Icons/AngleRight";
import IconMenuBars from "../Icons/MenuBars";
import IconToggleMenu from "../Icons/ToggleMenu";
import IconTornado from "../Icons/Tornado";
import IconObjectives from "../Icons/Objectives";
import IconMasterGoals from "../Icons/MasterGoals";
import IconSmartPlanner from "../Icons/SmartPlanner";
import IconProjects from "../Icons/Projects";
import IconCalendar from "../Icons/Calendar";

export default {
	data() {
		return {
			showMenuExtended: false,
			defaultOptionsLogo: {
				animationData: animationDataLogo.default,
				autoplay: false,
				loop: false,
			},
			finished: true,
			settingsRoutes: ["settings-subscriptions", "settings-work-schedules", "settings-departments", "settings-workstations", "settings-skills", "settings-profile"],
			objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK,
			organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK,
			subMenusActives: [],
		};
	},
	components: {
		Lottie,
		IconDailyPlan,
		IconChart,
		IconPeople,
		IconRoles,
		IconVacantJob,
		IconSettings,
		IconReports,
		IconLogout,
		IconDashboard,
		IconChartUp,
		IconProcedures,
		IconProcesses,
		IconArrow,
		IconMenuBars,
		IconToggleMenu,
		IconTornado,
		IconObjectives,
		IconMasterGoals,
		IconSmartPlanner,
		IconProjects,
		IconCalendar,
	},
	mounted() {
		if (!this.$cookies.get("sidebarMenuOpen")) {
			this.showMenuExtended = this.$resize && this.$mq.below(1000) ? false : true;
		} else {
			this.showMenuExtended = this.$cookies.get("sidebarMenuOpen") == 1 ? true : false;
		}

		if (this.$cookies.get("sidebarSubMenusActives")) {
			this.subMenusActives = JSON.parse(this.$cookies.get("sidebarSubMenusActives"));
		} else {
			this.subMenusActives = ["org", "objectives"];
		}
	},
	methods: {
		handleAnimationLogo(anim) {
			this.anim_logo = anim;
			this.anim_logo.setSpeed(1);
		},
		logoAnim() {
			if (this.finished && !this.bird_noti) {
				this.finished = false;
				this.anim_logo.play();
				setTimeout(() => {
					this.anim_logo.stop();
					this.finished = true;
				}, 5000);
			}
		},
		toggleMenu() {
			this.showMenuExtended = !this.showMenuExtended;
			this.$cookies.set("sidebarMenuOpen", this.showMenuExtended ? 1 : 0, "1m", null, process.env.VUE_APP_MAIN_DOMAIN);
		},
		toggleSubMenu(type) {
			if (this.subMenusActives.includes(type)) {
				this.subMenusActives.splice(this.subMenusActives.indexOf(type), 1);
			} else {
				this.subMenusActives.push(type);
			}
			this.$cookies.set("sidebarSubMenusActives", JSON.stringify(this.subMenusActives), "1m", null, process.env.VUE_APP_MAIN_DOMAIN);
		},
	},
};
</script>
