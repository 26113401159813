<template>
<div class="modal-tbf modal-full-page">
    <div class="container-modal opacity-page">
        <div class="header-modal">
            <div class="title">
                {{$t('reports.users')}}
            </div>
            <div class="actions">
                <button class="btn-tbf white only-icon close-btn" @click="cancelModal"><icon-close class="icon-close" /></button>
            </div>
        </div>
        <div v-if="loaded" class="header-filter">
			<div class="row-space-tbf search-row no-padding-top">
				<!-- <icon-search v-if="$resize && $mq.above(781)"/> -->
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('general.search_by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
					</div>
                    <div class="filter-btns">
						<date-range-picker
							ref="picker"
							class="calendar-filter"
                            :class="[{mobile: $resize && $mq.below(650)}]"
							opens="left"
							:locale-data="calendarFilterSettings"
							:singleDatePicker="$mq.below(650) ? 'range' : false"
							:showWeekNumbers="false"
							:showDropdowns="false"
							:ranges="false"
							:autoApply="true"
							:linkedCalendars="true"
							:maxDate="maxDateCalendar"
							v-model="dateRange"
							@update="updateRangeFilter"
							>
							<template v-slot:input="picker">
								<div class="input-picker">
                                    <div class="icon"><icon-date/></div>
									<div class="date" v-if="picker.startDate == picker.endDate">{{ picker.startDate | moment('DD MMM YYYY') }}</div>
									<div class="date" v-else>{{ picker.startDate | moment('DD MMM YYYY') }} - {{ picker.endDate | moment('DD MMM YYYY') }}</div>
									<icon-arrow class="icon-arrow right"/>
								</div>
							</template>
						</date-range-picker>
					</div>
				</div>
			</div>
		</div>
        <loader-modal-header-list v-else/>
        <template v-if="loadedList">
            <div class="data-list list-goals users" v-if="entries.length">
                <div class="row-space-tbf header-list">
                    <div class="content">
                        <div class="column-filter column-name">
                            <div class="text">{{ $t('general.name') }}</div>
                        </div>
                        <div v-if="$resize && $mq.above(501)" class="column-filter column-time">
                            <div class="text">{{ $t('reports.time_spent') }}</div>
                        </div>
                        <div  v-if="$resize && $mq.above(700)" class="column-filter column-date">
                            <div class="text">{{ $t('reports.access_date') }}</div>
                        </div>
                    </div>
                </div>

                <template v-for="entry in entries">
                    <div class="row-space-tbf row-list-item" :key="`entryItem${entry.id}`">
                        <!-- <div class="space-left"><icon-people v-if="$resize && $mq.above(781)"/></div> -->
                        <div class="content">
                            <div v-if="$resize && $mq.above(781)" class="border-hover"></div>
                            <div class="column-name-desc column-avatar column-name">
                                <img :src="entry.user.avatar" v-if="entry.user.avatar">
                                <div class="user-circle" v-else>
                                    <icon-user />
                                </div>
                                <div class="text">
                                    <div class="name">{{ entry.user.first_name + ' ' + (entry.user.last_name ? entry.user.last_name : '') }}</div>
                                    <div class="description">{{ entry.user.email }}</div>
                                </div>
                            </div>
                            <div v-if="$resize && $mq.above(700)" class="column-simple-text column-date">
                                <div class="text">{{entry.time}}</div>
                            </div>
                            <div v-if="$resize && $mq.above(501)" class="column-simple-text column-time">
                                <div class="text">{{entry.created_at | moment('DD MMM YYYY')}}</div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<div class="title">{{ $t('empty.title')}}</div>
				</div>
				<div class="space-right"></div>
			</div>
        </template>
        <loader-items-modal-list v-else/>

        <infinite-loading :identifier="infiniteId" @infinite="infiniteUsers" ref="infiniteLoadingEntriesProcedure"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconPeople from '../Icons/People'
import IconSearch from '../Icons/Search'
import IconDate from '../Icons/Date'
import IconArrow from '../Icons/Arrow'
import IconUser from '../Icons/User'
import LoaderItemsModalList from '../PagesLoaders/ItemsModalList'
import LoaderModalHeaderList from '../PagesLoaders/HeaderModalList'
import InfiniteLoading from 'vue-infinite-loading'

	export default {
		data() {
			return {
                loaded: false,
            	loadedList: false,
                maxDateCalendar: moment().format('YYYY-MM-DD'),
				dateRange: {
					startDate: moment().add(-90, 'days'),
					endDate: moment()
				},
				calendarFilterSettings: {
					firstDay: 1,
					format: 'DD-MM-YYYY',
					daysOfWeek:  moment.weekdaysMin(),
					monthNames: moment.monthsShort()
				},
                search_filter: '',
                entries: [],
                sortBy: '',
                page: 0,
                infiniteId: 1
			};
		},
        props: {
			data: String
		},
        components: {
            IconClose,
            IconPeople,
            IconSearch,
            IconDate,
            IconArrow,
            IconUser,
            LoaderItemsModalList,
            LoaderModalHeaderList,
            InfiniteLoading
        },
		mounted() {
            this.$refs.infiniteLoadingEntriesProcedure.status = 1
            this.$refs.infiniteLoadingEntriesProcedure.$emit('infinite', this.$refs.infiniteLoadingEntriesProcedure.stateChanger)
            
			setTimeout(() => {
				$('.opacity-page').addClass('show')
                this.loaded = true
			}, 0)
		},
        computed: {

        },
		methods: {
            searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.entries = []
				this.infiniteId += 1
			},
            infiniteUsers($state){
				var paramsCall = { view_more: this.page * 20}
                this.sendFilters(paramsCall)

				axios.get(`/${this.data}/procedure-logs`, { params: paramsCall })
				.then(({data}) => {
					if(data.data.length) {
						this.page++
						this.entries.push(...data.data)
						setTimeout(() => { $state.loaded() }, 200)

        				if(data.data.length < 20){
        					$state.complete()
        				}
					} else{
						$state.complete()
					}

        			this.loadedList = true
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
            sendFilters(paramsCall){
                if(this.search_filter){
                    paramsCall.search = this.search_filter
                }

                if(this.dateRange.startDate && this.dateRange.endDate) {
                    paramsCall.start_date = moment(this.dateRange.startDate).format('DD-MM-YYYY');
                    paramsCall.end_date = moment(this.dateRange.endDate).format('DD-MM-YYYY');
                }
            },
            updateRangeFilter(obiec){
                this.loadedList = false
                this.page = 0
                this.entries = []
                this.infiniteId += 1
			},
			cancelModal(){
				this.$emit("close_modal");
			},
		}
	};
</script>

<style lang="scss">
.column-avatar {
    &.column-name{
        .user-circle,
        img {
            margin-right: 20px;
            min-width: 36px;
        }
        .text{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }
}
.column-date,
.column-time{
    flex: 0 0 100px;
}
.column-name{
    flex: 1 1 auto;
    display: flex;
}
.filter-btns{
    display: flex;
    align-items: center;
    .btn-default-tbf{
        height: 36px;
        font-size: 12px;
        font-weight: 500;
        color: $primary;
        padding: 0 15px;
        min-width: 110px;
        text-align: left;
        display: flex;
        align-items: center;
        .icon-arrow{
            margin-left: auto;
        }
        .clearFilter{
            margin-left: auto;
            height: 12px;
            margin-right: -2px;
        }
    }
    .calendar-filter{
        margin-left: 20px;
    }
    .filter-status{
        margin-left: 20px;
    }
}
.input-picker{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .icon{
        margin-right: 10px;
        justify-content: center;
        display: flex;
        svg{
            height: 16px;
            width: auto;
            .fill-color{
                fill: $placeholderColor;
            }
        }	
    }
    .text{
        color:$placeholderColor
    }
    .date{
        flex: 1;
        font-size: 12px;
        color: $primary;
        text-transform: capitalize;
        line-height: normal;
    }
}
.row-space-tbf {
    &.no-padding-top {
        padding-top: 0 !important;
    }
}
#modal_entries_procedure_users {
    .header-modal {
        border-bottom: none;
        margin-bottom: 0;
    }
    &.modal-tbf{
        .container-modal{
            min-height: 500px;
        }
    }
    .row-space-tbf {
        &.list-empty{
            border-top: 1px solid #dcdee1;
            padding-top: 30px;
        }
    }
}
.form-submit.role > button{
    width: 50%;
}
</style>