<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/trash-icon.svg"></div>
			<div class="title">{{ $t('delete.title') }}</div>
		</div>
		<div class="description-modal">
			{{ $t('delete.question',{text: $t('delete.' + data.type)}) }}
	
			<div class="btns-delete" v-if="showTypeDelte">
				<div class="btn-box">
					<div class="btn-tbf grey" @click="deleteFlow = 0">
						<div class="checkbox-tbf simple" v-bind:class="{active: deleteFlow == 0}">
							<div class="checkmark"></div>
						</div>
						<div class="text">{{ $t('delete.just_this_step') }}</div>
					</div>
				</div>
				<div class="btn-box">
					<div class="btn-tbf grey" @click="deleteFlow = 1">
						<div class="checkbox-tbf simple" v-bind:class="{active: deleteFlow == 1}">
							<div class="checkmark"></div>
						</div>
						<div class="text">{{ $t('delete.delete_entire_flow') }}</div>
					</div>
				</div>
			</div>
	
			{{ $t('delete.atention') }}
		</div>
	
		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelDelete">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonSubmitDelete" class="btn-tbf red center" @click="submitDelete">
				<div class="loader"></div>
				<div class="text">{{ $t('delete.delete') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
import IconSquareCheck from "../Icons/SquareCheck";

export default {
	data() {
		return {
			deleteFlow: 0,
			showTypeDelte: false,
			processesIds: [],
			proceduresIds: [],
			parse_data: {},
			parse_json_modal: {}
		};
	},
	props: {
		data: Object
	},
	components: {
		IconSquareCheck
	},
	mounted() {
		if (this.data.type == 'step') {
			this.parse_data = JSON.parse(this.data.treeData)

			if (!['decision', 'end', 'loop'].includes(this.parse_data.type)) {
				this.showTypeDelte = true
			}
		}

		if (this.data.type == 'condition') {
			this.deleteFlow = 1
		}
	},
	methods: {
		cancelDelete() {
			this.$emit("close_modal", 'delete');
		},
		submitDelete() {
			var buttonName = `buttonSubmitDelete`
			var btnSubmit = document.getElementById(buttonName);
			var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
			var btnSubmitText = document.querySelector(`#${buttonName} .text`)

			btnSubmit.disabled = true

			btnSubmit.classList.add('loading')
			btnSubmitLoader.classList.add('onProgress')
			btnSubmitText.innerHTML = this.$t('btn-submit.loading')

			switch (this.data.type) {
				case 'step':
					this.deleteStep()
					break;
				case 'condition':
					this.deleteStep()
					break;
				case 'condition_edit':
					this.$emit("confirm_delete");
					break;
				case 'process':
					this.deleteProcess()
					break;
				case 'procedure':
					this.deleteProcedure()
					break;
				case 'user':
					this.deleteUser()
					break;
				case 'role':
					this.deleteRole()
					break;
				case 'category':
					this.deleteCategory()
					break;
				case 'version':
					this.deleteVersion()
					break;
				case 'custom_right':
					this.deleteCustomRight()
					break;
			}
		},
		deleteStep() {
			this.parse_data = JSON.parse(this.data.treeData);
			this.parse_json_modal = {...JSON.parse(this.data.fullJson)};
			
			this.processesIds = [];
			this.proceduresIds = [];

			var newJson = this.getObjects(this.parse_json_modal, this.parse_data.unique_id, this.parse_data)

			axios.post(`/procedures/${this.data.process.slug}/sync`, {
				version_id: this.data.process.version_id,
				procedures: this.proceduresIds,
				processes: this.processesIds,
				json: this.stringifyCircular(newJson)
			})
			.then(({ data }) => {
				this.$root.$emit('refreshProcessTree', { json: data.data.json, processes_info: data.data.processes_info, procedures_info: data.data.procedures_info })
			})
			.finally(() => {
				setTimeout(() => {
					this.$emit("close_modal")
				}, 100)
			})
		},
		deleteProcess() {
			axios.delete(`/processes/${this.data.model.slug}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				})
		},
		deleteProcedure() {
			axios.delete(`/procedures/${this.data.model.slug}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				})
		},
		deleteUser() {
			axios.delete(`/users/${this.data.model.slug}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				})
		},
		deleteRole() {
			axios.delete(`/roles/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				})
		},
		deleteCategory() {
			axios.delete(`/categories/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				})
		},
		deleteVersion() {
			axios.delete(`/process-versions/${this.data.model.version.id}`)
			.then(() => {
				setTimeout(() => {
					this.refreshPage('delete_version')
				}, 100)
			})
		},
		deleteCustomRight(){
			axios.delete(`/special-rights/${this.data.model.id}`)
			.then(() => {
				setTimeout(() => {
					this.refreshPage()
				}, 100)
			})
		},
		refreshPage(typeDelete = false) {
			var buttonName = `buttonSubmitDelete`
			var btnSubmit = document.getElementById(buttonName);
			var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
			var btnSubmitText = document.querySelector(`#${buttonName} .text`)

			btnSubmitLoader.classList.add('finish')
			setTimeout(() => {
				btnSubmitText.innerHTML = this.$t('btn-submit.success')
				btnSubmit.classList.add('completed')
				btnSubmitLoader.classList.remove('onProgress', 'finish')
				btnSubmit.classList.remove('loading')
				setTimeout(() => {
					btnSubmit.classList.remove('completed')
					btnSubmitText.innerHTML = this.$t('delete.delete')
					this.$emit("close_modal", typeDelete ? typeDelete : 'delete' );
				}, 1000)
			}, 300)

			switch (this.data.from) {
				case 'index_processes':
					this.$root.$emit('refreshProcessesIndex')
					break;
				case 'index_procedures':
					this.$root.$emit('refreshProceduresIndex')
					break;
				case 'index_users':
					this.$root.$emit('refreshUsersIndex')
					break;
				case 'index_roles':
					this.$root.$emit('refreshRolesIndex')
					break;
				case 'index_categories':
					this.$root.$emit('refreshCategoriesIndex')
					break;
				case 'modal_version':
					this.$router.push({ name: 'process-show', params: { slug: this.$route.params.slug } }).then(() => {
						this.$root.$emit('proccesNewVersion')
					})
					break;
				case 'index_rights':
					this.$root.$emit('refreshCustomRightsIndex')
					break;
			}
		},
		getObjects(obj, val, newVal) {
			var newValue = newVal;
			var objects = [];
			var idsToDelete = newVal.hasOwnProperty('is_parent') ? newVal.is_parent : [];

			for (var i in obj) {

				if (newVal.hasOwnProperty('selectedParentLoop') && i == 'unique_id' && obj.unique_id == newVal.selectedParentLoop.unique_id) {
					if(obj.is_parent && obj.is_parent.length === 0){
						delete obj.is_parent
					} else if(obj.is_parent){
						obj.is_parent.splice(obj.is_parent.findIndex(el => el == newVal.unique_id), 1)
					}
				}

				if (obj.children != null && obj.children.filter(el => { return el != null && typeof el === "object" && ( el.unique_id == val || idsToDelete.includes(el.unique_id) ) }).length ) {

					obj.children.filter(el => { return el != null && typeof el === "object" && ( el.unique_id == val || idsToDelete.includes(el.unique_id) ) })
					.map(elemFiltered => {

						var ObjIndex = obj.children.findIndex(el => el.unique_id == elemFiltered.unique_id)
						
						if(idsToDelete.find(el => el == elemFiltered.unique_id)) {
							idsToDelete.splice(idsToDelete.findIndex(el => el == elemFiltered.unique_id), 1)
							newValue.is_parent = idsToDelete
						}

						obj.children.splice(ObjIndex, 1)

						if(elemFiltered.unique_id == val) {
							if (newVal.children && newVal.children[0] && newVal.type != 'decision' && this.deleteFlow == 0) {
								// obj.children.push(newVal.children[0])
								obj.children.splice(ObjIndex, 0, newVal.children[0]);

								if (newVal.hasOwnProperty('condition')) {
									newVal.children[0].condition = newVal.condition
								}
							} else {
								if (this.data.type != 'condition') {
									if (obj.type == 'decision') {
										obj.children.splice(ObjIndex, 0, { unique_id: newVal.unique_id, condition: newVal.condition, type: 'blank' })
									} else if (obj.type == 'start') {
										obj.children.push({ unique_id: newVal.unique_id, type: 'blank' })
									} else {
										delete obj.children
									}
								}
							}
						} else {
							if (elemFiltered.children && elemFiltered.children[0] && elemFiltered.type != 'decision' && this.deleteFlow == 0) {
								obj.children.push(elemFiltered.children[0])

								if (elemFiltered.hasOwnProperty('condition')) {
									elemFiltered.children[0].condition = elemFiltered.condition
								}
							} else {
								if (this.data.type != 'condition') {
									if (obj.type == 'decision') {
										obj.children.splice(ObjIndex, 0, { unique_id: elemFiltered.unique_id, condition: elemFiltered.condition, type: 'blank' })
									} else if (obj.type == 'start') {
										obj.children.push({ unique_id: elemFiltered.unique_id, type: 'blank' })
									} else {
										delete obj.children
									}
								}
							}
						}
					})

				}

				if (!obj.hasOwnProperty(i)) continue;

				if (typeof obj[i] == 'object') {
					objects = objects.concat(this.getObjects(obj[i], val, newValue));
				}

				if (i == 'type') {
					if (obj['type'] == 'process') this.processesIds.push(obj['id'])
					else if (obj['type'] == 'procedure') this.proceduresIds.push(obj['id'])
				}
			}

			return obj;
		},
		stringifyCircular(obj){
			let cache = [];
			let str = JSON.stringify(obj, function(key, value) {
				if (typeof value === "object" && value !== null) {
					if (cache.indexOf(value) !== -1) {
						// Circular reference found, discard key
						return;
					}
					// Store value in our collection
					cache.push(value);
				}
				return value;
			});

			cache = null; // reset the cache
			return str;
		}
	}
};
</script>