<template>
	<div class="form-template">
        <template v-if="loadedApplications">
            <slot></slot>
            <notifications/>
            <div class="overflow-modal" style="position: fixed" v-if="show_overflow">
                <div class="bg-modals" @click="closeModals()"></div>
                <modal-role id="modal_role" v-if="show_modal.role" :data="data.role" @close_modal="closeModal('role')" />
                <modal-category id="modal_category" v-if="show_modal.category" :data="data.category" @close_modal="closeModal('category')" />
                <modal-restore-version id="modal_restore_version" v-if="show_modal.restore_version" :data="data.restore_version" @close_modal="closeModal('restore_version')" />
                <modal-history-version id="modal_history_version" v-if="show_modal.history_version" :data="data.history_version" :item="item" @close_modal="closeModal('history_version')" />
            </div>
        </template>
        <div class="page-loader-on-start" v-else>
            <div class="loader-spin"></div>
        </div>
	</div>
</template>

<script>
    import ModalRole from "../Modals/Role"
    import ModalCategory from "../Modals/Category"
    import ModalRestoreVersion from "../Modals/RestoreVersion"
    import ModalHistoryVersion from "../General/SidebarHistoryVersions"
    import Notifications from "../General/Notifications"

    export default {
        data() {
            return {
                loadedApplications: false,
                show_overflow: false,
                show_modal: {
					role: false,
                    category: false,
                    restore_version: false,
                    history_version: false
                },
                data: {
                    restore_version: {},
                    history_version: {}
                },
                item: {}
            }
        },
        components: {
            ModalRole,
            ModalCategory,
            ModalRestoreVersion,
            ModalHistoryVersion,
            Notifications
        },
        mounted(){
            this.$root.$on("open_modal", (type, data = false, itemId = false) => {
                this.active_modal = type
                this.item[type] = itemId ? itemId : ''
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })

            if(this.$store.state.applications.applications.length){
                this.loadedApplications = true;
            } else {
                var refreshIntervalApps = setInterval(() => {
                    if(this.$auth.check()){
                        if(this.$store.state.applications.applications.length){
                            clearInterval(refreshIntervalApps);
                            this.loadedApplications = true;
                        }
                    }
                }, 50);
            }

            this.checkLanguage();
        },
        methods:{ 
            closeModals(){
                var arrayModalsNeedVerification = ['key_result', 'objective', 'master_goal', 'user', 'add_promise', 'evaluate_promise', 'role']
                if(arrayModalsNeedVerification.includes(this.active_modal)){
                    this.$root.$emit('confirm_close')
                }else{
                    if(this.active_modal == 'procedure') {
                        this.closeModal(false, false)
                        this.active_modal = 'add_step'
                    }else {
                        this.closeModal()
                    }
                }
            },
            closeModal(type = false, closeOverflow = true){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false

                    if(closeOverflow){
                        $('.overflow-modal').removeClass('show')
                        setTimeout(() => {
                            this.show_overflow = false;
                        }, 200)
                    }
                }, 200);
            },
            checkLanguage(){
                if(this.$auth.check()){
                   this.$i18n.locale = this.$auth.user().language
                   this.$cookies.set('language', this.$auth.user().language, '1m')
                }else if(this.$cookies.get('language')){
                   this.$i18n.locale = this.$cookies.get('language')
                }
            },
        }
    }
</script>

<style lang="scss">
    @import "../../styles/templates/form-template.scss";
    
</style>