<template>
	<div class="modal_info payment_error">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/warning.svg"></div>
			<div class="title">{{ $t('subscription.people-max-reached-title') }}</div>
		</div>
		<div class="description-modal">{{ $t('subscription.people-max-reached-description') }}</div>

		<div class="button-actions">
			<button class="btn-tbf grey center" @click="closeModal">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button class="btn-tbf blue center" @click="changeModal">
				<div class="text">{{ $t('subscription.change') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {};
		},
		mounted() {},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			changeModal(){
				window.location.replace(`${process.env.VUE_APP_CENTRAL_FE_LINK}/price`);
                return true;
				
				this.$root.$emit('change_modal', 'change_subscription');
			},
		}
	};
</script>