<template>
	<div class="sidebar-notifications">
		<div class="top">
			<h1>{{ $t("notifications.title") }}</h1>
			<div class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close /></div>
		</div>

		<div class="notification-header">
			<div class="notifications-type" v-bind:class="{ active: active_tab == 'activity' }" @click="changeTab('activity')">{{ $t("notifications.activity") }}</div>
			<div class="notifications-type" v-bind:class="{ active: active_tab == 'new' }" @click="changeTab('new')">{{ $t("notifications.new") }} ({{ countUnread }})</div>
		</div>

		<div
			class="notifications-content"
			v-bind:class="{ center: (active_tab == 'activity' && Object.keys(activity_notifications).length == 0) || (active_tab == 'new' && Object.keys(notifications).length == 0) }"
			v-if="loaded"
		>
			<!-- Activitatea companiei -->

			<div class="notification-list" v-if="active_tab == 'activity'">
				<div
					class="notification-box"
					:id="'notification-box-' + notification.id"
					v-for="notification in activity_notifications"
					v-bind:class="{ 'is-link': notification.need_link }"
					:key="`notification-activity-${notification.id}`"
				>
					<div class="left-side" v-if="$resize && $mq.above(600)"><icon-arrow :id="'notification-' + notification.id" class="icon-arrow right" /></div>
					<div class="center" @click="notification.need_link ? viewItem(notification.entity_slug, notification, 'activity') : ''">
						<img v-if="notification.avatar != null" :src="notification.avatar" class="img-responsabile" />
						<div class="bell" v-else><bell /></div>
						<div class="content-notification">
							<p v-html="notification.name"></p>
							<span class="date">{{ notification.created_at | moment("D MMMM YYYY") }}</span>
						</div>
					</div>
					<div class="right-side" v-if="$resize && $mq.above(600)"></div>
				</div>
				<infinite-loading :identifier="1" @infinite="infiniteNotificationsActivity"
					><div slot="spinner"></div>
					<div slot="no-more"></div>
					<div slot="no-results"></div
				></infinite-loading>
			</div>

			<div class="no-notifications" v-if="Object.keys(activity_notifications).length == 0 && active_tab == 'activity'">
				<icon-large-bell class="icon" />
				<p>{{ $t("notifications.all-good") }}</p>
				<span>{{ $t("notifications.no-notifications") }}</span>
			</div>

			<!-- Notificari click-uibile -->
			<div class="notification-list" v-if="active_tab == 'new'">
				<div
					class="notification-box"
					:id="'notification-box-' + notification.id"
					v-for="notification in notifications"
					v-bind:class="{ 'is-link': notification.need_link }"
					:key="`notification-${notification.id}`"
				>
					<div class="left-side" v-if="$resize && $mq.above(600)"><icon-arrow :id="'notification-' + notification.id" class="icon-arrow right" /></div>
					<div class="center" @click="notification.need_link ? viewItem(notification.entity_slug, notification, 'personal') : markAsRead(notification)">
						<img v-if="notification.avatar != null" :src="notification.avatar" class="img-responsabile" />
						<div class="bell" v-else><bell /></div>
						<div class="content-notification" v-bind:class="{ new: !notification.seen }">
							<p v-html="notification.name"></p>
							<span class="date">{{ notification.created_at | moment("D MMMM YYYY") }}</span>
						</div>
					</div>
					<div class="right-side" v-if="$resize && $mq.above(600)"></div>
				</div>
				<infinite-loading :identifier="2" @infinite="infiniteNotifications"
					><div slot="spinner"></div>
					<div slot="no-more"></div>
					<div slot="no-results"></div
				></infinite-loading>
			</div>

			<div class="no-notifications" v-if="Object.keys(notifications).length == 0 && active_tab == 'new'">
				<icon-large-bell class="icon" />
				<p>{{ $t("notifications.all-good") }}</p>
				<span>{{ $t("notifications.no-notifications") }}</span>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import IconClose from "../Icons/Close";
import Bell from "../Icons/Bell";
import IconArrow from "../Icons/Arrow";
import IconEditDots from "../Icons/EditDots";
import IconLargeBell from "../Icons/LargeBell";
import InfiniteLoading from "vue-infinite-loading";

export default {
	data() {
		return {
			loaded: true,
			notifications: [],
			activity_notifications: [],
			countUnread: 0,
			active_tab: "new",
			view_more: 20,
			counter: 1,
			viewMoreUnread: 20,
			counterUnread: 1,
			objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK,
			organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK,
		};
	},
	props: {},
	components: {
		InfiniteLoading,
		IconClose,
		Bell,
		IconArrow,
		IconEditDots,
		IconLargeBell,
	},
	async mounted() {
		await this.getNotifications();

		this.$root.$emit("clear_notifications");

		this.$root.$on("updateNotificationsList", (parsedData) => {
			if (parsedData.for_activity) {
				this.activity_notifications.unshift(parsedData);
			}
			if (parsedData.for_personal) {
				this.notifications.unshift(parsedData);
			}
		});
	},
	methods: {
		async getNotifications() {
			await axios
				.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`)
				.then(({ data }) => {
					this.notifications = data.data.notification_logs;
					this.countUnread = data.data.count_unread;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.active_tab = "new";
				});
		},
		getActivityNotifications() {
			axios
				.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list?type=activity`)
				.then(({ data }) => {
					this.activity_notifications = data.data.notification_logs;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.active_tab = "activity";
					this.loaded = true;
				});
		},
		infiniteNotifications($state2) {
			axios
				.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`, { params: { view_more: this.viewMoreUnread } })
				.then(({ data }) => {
					if (data.data.notification_logs.length) {
						this.counterUnread++;
						this.viewMoreUnread = 20 * this.counterUnread;
						this.notifications.push(...data.data.notification_logs);
						$state2.loaded();
					} else {
						$state2.complete();
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				});
		},
		infiniteNotificationsActivity($state) {
			axios
				.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`, { params: { type: "activity", view_more: this.view_more } })
				.then(({ data }) => {
					if (data.data.notification_logs.length) {
						this.counter++;
						this.view_more = 20 * this.counter;
						this.activity_notifications.push(...data.data.notification_logs);
						$state.loaded();
					} else {
						$state.complete();
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
					s;
				});
		},
		viewItem(itemSlug, notification, column) {
			if (notification.frontend_type == "procedures") {
				this.$router.push({ name: "procedure-show", params: { slug: itemSlug } });
			} else if (notification.frontend_type == "processes") {
				this.$router.push({ name: "process-show", params: { slug: itemSlug } });
			} else if (notification.frontend_type == "tasks") {
				window.location.href = `${this.organigram_fe_link}/dashboard?open_task=${notification.entity_slug}&date=${notification.additional_data.date}`;
			} else if (notification.frontend_type == "meetings") {
				window.location.href = `${this.organigram_fe_link}/dashboard`;
			} else if (notification.frontend_type == "evaluations") {
				window.location.href = `${this.organigram_fe_link}/user/${itemSlug}`;
			} else if (notification.frontend_type == "role_responsibilities") {
				window.location.href = `${this.organigram_fe_link}/user/${itemSlug}`;
			} else if (notification.frontend_type == "announces") {
				window.location.href = `${this.organigram_fe_link}/announcements/${itemSlug}`;
			} else if (notification.frontend_type == "departments") {
				window.location.href = `${this.organigram_fe_link}/company`;
			} else {
				window.location.href = `${this.objectives_fe_link}/obiectiv/${itemSlug}`;
			}

			if (column == "personal") {
				this.markAsRead(notification);
			}

			this.$auth.fetch();
			this.closeModal();
		},
		markAsRead(notification) {
			axios
				.patch(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notifications/${notification.id}`)
				.then(({ data }) => {
					var contentNotification = document.querySelector(`#notification-box-${notification.id} .content-notification`);
					if (contentNotification) {
						contentNotification.classList.remove("new");
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				});
		},
		closeModal() {
			this.$emit("close_modal");
		},
		changeTab(val) {
			if (val == "new") {
				this.getNotifications();
			} else {
				this.getActivityNotifications();
			}
		},
	},
};
</script>
