<template>
	<div class="sidebar-mobile">
		<div class="main-menu" v-if="$auth.check()">
			<div class="item-menu">
				<a :href="`${organigram_fe_link}/dashboard`">
					<icon-dashboard class="icon-item"/>
				</a>
			</div>
			<div class="item-menu">
				<a :href="`${organigram_fe_link}/company`">
					<icon-chart class="icon-item"/>
				</a>
			</div>
			<div class="item-menu">
				<a :href="`${organigram_fe_link}/projects`">
					<icon-projects class="icon-item"/>
				</a>
			</div>
			<div class="item-menu">
				<router-link :to="{name: 'procedures' }" :class="{active: $route.name == 'procedures'}">
					<icon-procedures class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu">
				<a :href="`${objectives_fe_link}/obiective`">
					<icon-objectives class="icon-item"/>
				</a>
			</div>
			<div class="item-menu">
				<a :href="`${organigram_fe_link}/users`">
					<icon-people class="icon-item"/>
				</a>
			</div>
				<!-- <router-link :to="{name: !$auth.user().is_entrepreneur ? 'settings-profile' : $auth.user().is_entrepreneur && settingsRoutes.includes($route.name) ? $route.name : 'settings-departments'}" :class="{active: settingsRoutes.includes($route.name)}">
					<icon-menu-bars class="icon-item"/>
				</router-link> -->
			<div class="item-menu" @click="showModal('settings_mobile')">
				<a>
					<icon-menu-bars class="icon-item"/>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import IconChart from '../Icons/Chart'
	import IconPeople from '../Icons/People'
	import IconDashboard from '../Icons/Dashboard'
	import IconProcedures from '../Icons/Procedures'
	import IconObjectives from '../Icons/Objectives'
	import IconMenuBars from '../Icons/MenuBars'
	import IconProjects from '../Icons/Projects'

	export default {
		data() {
			return {
				settingsRoutes: ["settings-subscriptions", "settings-work-schedules", "settings-departments", "settings-workstations", "settings-skills", "settings-profile"],
				objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK,
				organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK
			};
		},
		components: {
			IconChart,
			IconPeople,
			IconDashboard,
			IconProcedures,
			IconProjects,
			IconObjectives,
			IconMenuBars
		},
		computed: {
			soft_procedures() {
				return this.$store.getters['applications/getApplication']('Proceduri');
			},
			soft_objectives() {
				return this.$store.getters['applications/getApplication']('Obiective');
			},
			soft_organigram() {
				return this.$store.getters['applications/getApplication']('Organigrama');
			}
		},
		methods: {
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	};
</script>